@import "../../../styles/base/abstracts";

.hbs-article-tease {
  width: 100%;

  .hbs-tease-row--mixed-tease & {
    img {
      object-fit: cover;
      aspect-ratio: 3 / 2;
      width: auto;
    }
  }

  &::after {
    content: "";
    clear: both;
    display: table;
  }

  .hbs-article-tease__title__link {
    @include unlink;
    @include subtle-link($c-text);
  }

  .hbs-article-tease__image {
    position: relative;

    .hbs-media-asset--image {
      pointer-events: none;
    }

    .hbs-article-tease__image__link {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      @include grid-child(margin);
      width: auto;

      &--cover {
        margin: 0;
        &:hover,
        &:focus {
          background-color: $c-bg-inverse !important;
        }
      }
    }
  }

  .hbs-article-tease__image:focus
    + .hbs-article-tease__content
    .hbs-article-tease__title__link,
  .hbs-article-tease__image:hover
    + .hbs-article-tease__content
    .hbs-article-tease__title__link {
    @include hover-link-styles($c-spot, $c-text);
  }

  .hbs-article-tease__title {
    @include h5;
    @include margin(x x $spacing-xs x);
    display: block;
  }

  .hbs-article-tease__teaser {
    @include baseline-text;
    @include margin(x x $spacing-xs x);
    color: $c-text-light;
    display: block;

    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;

    @include mq($bp-tablet) {
      -webkit-line-clamp: 2;
    }
  }
  &--content .hbs-article-tease__teaser {
    -webkit-line-clamp: 6;
  }

  .hbs-article-tease__meta {
    @include small-text;
    color: $c-text-light;
    display: block;
    line-height: $lh-md;
  }

  .hbs-article-tease__source,
  .hbs-article-tease__download {
    @include small-text;
    margin-top: $spacing-xs;
    max-width: 35em;
  }

  .hbs-article-tease__source-link,
  .hbs-article-tease__download-link {
    @include link($c-text-light);
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    padding-bottom: 0.2em;
  }

  .hbs-article-tease__overline {
    @include baseline-text($font-weight: $fw-semibold);
    color: $c-spot;
    display: block;
    margin-bottom: $spacing-xxs;

    a {
      @include subtle-link($c-spot, $c-spot);
    }
  }

  .hbs-participant-story-media__image {
    aspect-ratio: 3 / 2;
    background-color: $c-bg-inverse;
    overflow: hidden;
    width: auto;
    pointer-events: none;
    clear: both;

    .hbs-media-asset__wrapper {
      background-color: transparent;
      height: 100%;
      width: 100%;
      padding: 15%;
      justify-content: center;
      display: flex;
      overflow: hidden;
      position: relative;
    }

    img {
      aspect-ratio: 1 / 1;
      object-fit: cover;
      object-position: center center;
      width: auto;
      height: 100%;
    }
  }

  .hbs-article-tease__multi-link__list {
    display: flex;
    flex-wrap: wrap;
    gap: $spacing-xs;
    margin-top: $spacing-sm;

    @include mq($bp-tablet-xl) {
      gap: $spacing-sm;
    }
  }

  .hbs-article-tease__multi-link__link {
    @include padding($spacing-xxs $spacing-xs);
    @include small-text;
    @include color-link;
    border-radius: 5px;
    display: inline-block;
    transition: border-color $transition;
    border: $border-dark;

    &:hover,
    &:focus {
      border-color: $c-spot;
    }

    @include mq($bp-tablet-xl) {
      @include padding($spacing-xs);
    }
  }
}

// alignment specific spacing + style

.hbs-article-tease__content {
  margin-top: $spacing-md;
}

.hbs-article-tease--logo {
  margin-top: $spacing-md;

  .hbs-article-tease__content {
    margin-top: $spacing-sm;
  }
}

// Tease Feed
.hbs-tease-feed {
  .hbs-tease-feed__item {
    @include border($dir: top, $padding: 0);
    position: relative;
  }
  .hbs-article-tease {
    @include ungrid;
    @include block-spacing;
    position: relative;
  }

  .hbs-article-tease__image {
    @include grid-child;
    clear: right;
    display: block;
    float: right;
    margin-top: $spacing-xxs; // adjusting for optical spacing
    order: 2;
    overflow: hidden;
    width: $col-4;

    @include mq($bp-tablet) {
      width: nested-cols(5, 16);
      margin-top: 0; // adjusting for optical spacing
    }

    @include mq($bp-desktop) {
      width: nested-cols(4, 12);
    }
  }

  .hbs-article-tease__content {
    @include grid-child;
    margin-top: 0;
    order: 1;
  }

  .hbs-article-tease__image + .hbs-article-tease__content {
    @include mq($bp-desktop) {
      width: nested-cols(8, 12);
    }

    @include mq($bp-desktop-xl) {
      margin-right: nested-cols(1, 12);
      width: nested-cols(7, 12);
    }
  }

  .hbs-component--tease-feed.hbs-global-align-full &,
  .hbs-component--tease-feed.hbs-global-align-wide & {
    @include mq($bp-desktop) {
      .hbs-article-tease__title {
        @include h4;
      }
    }
  }

  .hbs-global-align-center & {
    .hbs-article-tease__image {
      @include mq($bp-tablet) {
        width: nested-cols(4, 16);
      }

      @include mq($bp-desktop) {
        width: nested-cols(2, 9);
      }

      @include mq($bp-desktop-xl) {
        width: nested-cols(2, 8);
      }
    }

    .hbs-article-tease--compressed {
      @include padding($spacing-md 0);
    }

    .hbs-article-tease__image + .hbs-article-tease__content {
      @include mq($bp-tablet) {
        width: nested-cols(12, 16);
      }

      @include mq($bp-desktop) {
        width: nested-cols(7, 9);
      }

      @include mq($bp-desktop-xl) {
        width: nested-cols(6, 8);
        margin-right: 0;
      }
    }
  }
}

// Tease Row
.hbs-tease-row {
  .hbs-article-tease {
    .hbs-article-tease__title {
      @include h5;
    }
  }

  .hbs-article-tease--no-image {
    @include padding($spacing-md);
    position: relative;
    transition: background-color $transition;

    @include hover {
      @include theme-dark;
      background-color: $c-bg;
    }

    .hbs-article-tease__overline {
      position: relative;
      margin-bottom: $spacing-xs;
      z-index: $z-index-1;

      a {
        color: $c-text;
      }
    }

    .hbs-article-tease__title {
      @include h4($small: true);
      margin-bottom: $spacing-xs;
      position: static;
      transform: none;
    }

    .hbs-article-tease__title__link {
      position: static;
      color: $c-text;
      text-decoration: none;

      @include hover {
        text-decoration: none;
      }

      &:after {
        content: "";
        position: absolute;
        inset: 0;
        cursor: pointer !important;
        display: flex;
      }
    }

    .hbs-article-tease__teaser {
      margin-bottom: $spacing-xs;
    }

    .hbs-article-tease__content {
      @include margin(0);
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    .hbs-article-tease__meta {
      transition: color $transition;
      margin-top: auto;
    }
  }

  &.hbs-tease-row--2-up,
  &.hbs-tease-row--3-up {
    @include mq($bp-tablet) {
      .hbs-article-tease--no-image {
        .hbs-article-tease__title {
          .hbs-global-align-center & {
            @include h5;
          }
          .hbs-global-align-full & {
            @include h4;
          }
        }
      }
    }
  }

  &.hbs-tease-row--1-up {
    .hbs-article-tease {
      @include mq($bp-desktop) {
        .hbs-article-tease__title {
          @include h4;
        }
      }
    }
  }

  &.hbs-tease-row--2-up {
    .hbs-global-align-wide &,
    .hbs-global-align-full & {
      .hbs-article-tease {
        @include mq($bp-desktop) {
          .hbs-article-tease__title {
            @include h4;
          }
        }
      }
    }
  }
}

// Content Tease Cards
.hbs-tease-row__tease-mod .hbs-article-tease.hbs-article-tease--content {
  align-self: stretch;
  width: 100%;

  .hbs-article-tease__content {
    justify-content: initial;
    margin-top: 0;
    padding-top: $spacing-md;
  }

  .hbs-article-tease__content__card {
    @include padding($spacing-md);
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;

    .hbs-article-tease__title {
      @include h5;

      @include mq($bp-tablet) {
        min-height: 2.4em;
      }
      transform: none;
      margin-left: -0.06em;

      .hbs-global-align-full &,
      .hbs-global-align-wide & {
        @include h4($small: true);
      }
    }
    .hbs-article-tease__content-wrapper--small {
      display: flex;
      justify-content: space-between;
      width: 100%;

      .hbs-article-tease__title--small {
        font-family: $ff-sans;
        font-weight: $fw-semibold;
        font-size: $fs-sm;
        line-height: $lh-base;
        margin-bottom: $spacing-md;
        min-height: auto;
        transform: none;
        margin-left: -0.06em;
      }
    }
  }

  .hbs-article-tease__content--link {
    background-color: $c-bg;
    text-decoration: none;
    transition: background-color $transition;

    &:focus:not(:hover) {
      outline-offset: -8px !important;
    }

    @include hover {
      @include theme-dark;
      color: $c-text;

      .hbs-icon {
        .arrow {
          animation: swipe 0.5s ease-in-out;
        }
      }
    }

    a {
      text-decoration: none;
      &:hover {
        color: inherit;
      }
      &::after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
      }
    }

    .hbs-article-tease__arrow {
      margin-top: auto;
      padding-top: $spacing-md;
      flex-shrink: 0;

      .hbs-article-tease__arrow-icon--external {
        transform: rotate(-45deg);
      }

      .hbs-icon {
        transition: transform $transition;
        @include size(1em);
      }
      svg {
        pointer-events: none;
      }
    }

    &.hbs-article-tease__content--small {
      justify-content: flex-start;
      min-height: $spacing-xxxl;

      a {
        text-decoration: none;
        &:hover {
          color: inherit;
        }
        &::after {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
        }
      }

      .hbs-article-tease__arrow {
        display: flex;
        margin-top: 0;
        padding-top: 0;

        .hbs-icon {
          @include size($spacing-sm);
          margin-left: $spacing-md;
          margin-top: $spacing-xxs;
          flex-shrink: 0;
          transition: transform 0.2s cubic-bezier(0.65, 0, 0.35, 1);
        }
      }
    }
  }

  &[data-theme="unset"] .hbs-article-tease__content--link {
    box-shadow: 0 0 0 1px inset $c-border-light;
  }
  @each $theme in $bg-themes {
    .hbs-page-section[data-theme="#{$theme}"]
      &[data-theme="#{$theme}"]
      .hbs-article-tease__content--link {
      box-shadow: 0 0 0 1px inset $c-border-light;
    }
  }
}

// Text Only
.hbs-article-tease--text-only {
  .hbs-article-tease__content {
    margin-top: 0;

    .hbs-editorial-topper__tease-row & {
      margin-top: $spacing-md;
    }
  }

  .hbs-article-tease__title {
    @include h5;
  }
}

// Small
.hbs-article-tease__content.hbs-article-tease__content--link.hbs-article-tease__content--small {
  @include hover {
    @include theme-crimson;
    color: $c-text;
  }
}

.hbs-article-tease__media--inset {
  background-color: $c-bg-inverse;
  padding: 15%;
}

.hbs-article-tease__media--inset,
.hbs-article-tease__media--cover {
  aspect-ratio: 3/2;
  overflow: hidden;

  width: auto;

  .hbs-media-asset__wrapper {
    background-color: transparent;
    height: 100%;
    width: 100%;
    justify-content: center;
  }

  img {
    object-fit: contain;
    width: auto;
    height: 100%;
  }
}

.hbs-article-tease__media--cover {
  padding: 10%;

  img {
    filter: drop-shadow(2px 8px 8px rgba(0, 0, 0, 0.05))
      drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.05));
  }
}

.hbs-article-tease__content:not(.tease-without-media) {
  .hbs-article-tease__title {
    max-width: 25em;
  }
  .hbs-article-tease__teaser {
    max-width: 30em;
  }
}

@include mq($bp-mobile, $bp-tablet) {
  .hbs-tease-feed {
    .hbs-article-tease:not(.hbs-article-tease--text-only)
      .hbs-article-tease__content:not(.tease-without-media) {
      max-width: 50%;
    }

    .hbs-article-tease__image {
      max-width: 50%;
      width: 50%;
      .hbs-participant-story-media__image {
        height: 100%;
        width: auto;

        .hbs-media-asset__wrapper {
          padding: 8%;
        }
      }
    }
  }
}
